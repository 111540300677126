<template>
  <main>

    <div class="bg-black padding pt-0">
      <div class="row justify-end">
        <div class="column w-full lg:w-9/12">
          <p class="mb-4"><strong>Filter by</strong></p>
        </div>
      </div>
      <div class="row justify-end">
        <div class="flex flex-wrap w-full lg:w-9/12">

          <!-- Filter for the categories -->
          <div class="column w-full lg:w-1/3">

            <div class="select-dropdown">
            <h5>{{ categ }}</h5>

            <div class="select-dropdown__options">

              <!-- "All" option -->
              <div>
                <input type="radio"
                       :value="'Project type'"
                       name="categ"
                       v-model="categ"
                       v-bind:value="'all'"
                       v-bind:id="'categ-all'"
                       v-on:change="updateSelectedFilter($event, 'categoryType')"
                />
                <label v-bind:for="'categ-all'">All</label>
              </div>
              <!-- / "All" option -->

              <!-- dynamic options -->
              <div v-for="category in categories">
                <input type="radio"
                       :value="category.title"
                       name="categ"
                       v-model="categ"
                       v-bind:value="category.id"
                       v-bind:id="category.id"
                       v-on:change="updateSelectedFilter($event, 'categoryType')"
                />
                <label v-bind:for="category.id">
                  {{ category.title }}
                </label>
              </div>
              <!-- / dynamic options -->
            </div>
          </div>

          </div>
          <!-- / Filter for the categories -->


          <!-- Filter for the expertise areas  -->
          <div class="column w-full lg:w-1/3">

              <div class="select-dropdown">
                  <h5>{{ expert }}</h5>

                  <div class="select-dropdown__options">

                  <!-- "All" option -->
                  <div>
                    <input type="radio"
                           :value="'Expertise'"
                           name="expert"
                           v-model="expert"
                           v-bind:value="'all'"
                           v-bind:id="'expert-all'"
                           v-on:change="updateSelectedFilter($event, 'expertise')"
                    />
                    <label v-bind:for="'expert-all'">All</label>
                  </div>
                  <!-- / "All" option -->

                  <!-- dynamic options -->
                  <div v-for="expertiseItem in expertise">
                    <input type="radio"
                           :value="expertiseItem.title"
                           name="expert"
                           v-model="expert"
                           v-bind:value="expertiseItem.id"
                           v-bind:id="expertiseItem.id"
                           v-on:change="updateSelectedFilter($event, 'expertise')"
                    />
                    <label v-bind:for="expertiseItem.id">
                      {{ expertiseItem.title }}
                    </label>
                  </div>
                  <!-- / dynamic options -->

                </div>
            </div>

          </div>
          <!-- / Filter for the expertise areas  -->


          <!-- Filter for the available regions (just shows the ones available)  -->
          <div class="column w-full lg:w-1/3">

              <div class="select-dropdown">
                   <h5>{{ reg }}</h5>

                   <div class="select-dropdown__options">

                   <!-- "All" option -->
                   <div>
                     <input type="radio"
                            :value="'Region'"
                            name="reg"
                            v-model="reg"
                            v-bind:value="'all'"
                            v-bind:id="'reg-all'"
                            v-on:change="updateSelectedFilter($event, 'expertise')"
                     />
                     <label v-bind:for="'reg-all'">All</label>
                   </div>
                   <!-- / "All" option -->

                   <!-- dynamic options -->
                   <div v-for="(region, index) in regions">
                     <input type="radio"
                            :value="getRegionLabel(index)"
                            name="reg"
                            v-model="reg"
                            v-bind:value="region"
                            v-bind:id="region"
                            :selected="region.selected"
                            v-if="index % 2 === 0"
                            v-on:change="updateSelectedFilter($event, 'region')"
                     />
                     <label v-bind:for="region" v-if="index % 2 === 0">
                       {{ getRegionLabel(index) }}
                     </label>
                   </div>
                   <!-- / dynamic options -->

                 </div>

               </div>
               <!-- / Filter for the expertise areas  -->
              </div>


        </div>
      </div>
    </div>

    <div class="paginate-container bg-black overflow-hidden" data-scroll>
      <paginate name="items" ref="paginator" :list="items" :per="this.postsnumbers" class="paginate-list row">
        <li v-for="project in paginated('items')" v-bind:class="project.displayField + project.alignContent">

          <div class="list-wrapper" v-if="project.displayField === 'wide'">
            <div class="img-wrap">
              <a class="img-link" v-if="project.url && project.isAProject" v-bind:href="project.url">
                <picture>
                  <source media="(min-width: 1024px)" v-bind:srcset="project.image">
                  <img class="w-full mb-6 lg:mb-0" v-bind:src="project.imageMobile" v-bind:alt="project.name">
                </picture>
              </a>

              <a class="img-link" v-else-if="project.url && !project.isAProject" v-bind:href="project.url">
                <picture>
                  <source media="(min-width: 1024px)" v-bind:srcset="project.image">
                  <img class="w-full mb-6 lg:mb-0" v-bind:src="project.imageMobile" v-bind:alt="project.name">
                </picture>
              </a>

              <picture v-else>
                <source media="(min-width: 1024px)" v-bind:srcset="project.image">
                <img class="w-full mb-6 lg:mb-0" v-bind:src="project.imageMobile" v-bind:alt="project.name">
              </picture>
            </div>

            <div class="copy-wrap">
              <p><strong>{{ project.name }}</strong></p>
              <p class="mb-6 capitalize">{{ project.subheading }}</p>
              <div class="small-text mb-6" v-if="project.description" v-html="project.description"></div>
              <a class="link" v-if="project.url && project.isAProject" v-bind:href="project.url">View project</a>
              <a class="link" v-if="project.url && !project.isAProject" v-bind:href="project.url">See more</a>
            </div>
          </div>

          <div v-if="project.displayField === 'compact' ">

            <a class="img-link" v-if="project.url && project.isAProject" v-bind:href="project.url">
              <picture>
                <img class="w-full mb-6" v-bind:src="project.image" v-bind:alt="project.name">
              </picture>
            </a>

            <a class="img-link" v-else-if="project.url && !project.isAProject" v-bind:href="project.url">
              <picture>
                <img class="w-full mb-6" v-bind:src="project.image" v-bind:alt="project.name">
              </picture>
            </a>

            <img class="w-full mb-6" v-else v-bind:src="project.image" v-bind:alt="project.name">

            <p><strong>{{ project.name }}</strong></p>

            <p class="mb-6 capitalize">{{ project.subheading }}</p>

            <div class="small-text mb-6" v-if="project.description" v-html="project.description"></div>

            <a class="link" v-if="project.url && project.isAProject " v-bind:href="project.url">View project</a>

            <a class="link" v-if="project.url && !project.isAProject " v-bind:href="project.url">See more</a>
          </div>

        </li>
      </paginate>
    </div>

    <div class="bg-black">
      <div class="row">
        <div class="column flex justify-center">
          <paginate-links for="items" id="pagi" :limit="3" :show-step-links="true"
              @change="onLangsPageChange"
              :step-links="{ next: 'Next', prev: 'Prev'}">
          </paginate-links>
        </div>
      </div>
    </div>

  </main>
</template>

<script>

import _ from 'lodash';

export default {

  props: [
    'projects',
    'categories',
    'regions',
    'postsnumbers',
    'expertise'
  ],

  data() {
    return {
      categ: 'Project type',
      expert: 'Expertise',
      reg: 'Region',
      selectedCategory: '',
      selectedExpertise: '',
      selectedRegion: '',
      projectsArray: this.projects,
      items: this.projects,
      locations: this.regions,
      paginate: ['items'],
      expertiseCurrentFilter: 'all',
      categoryTypeCurrentFilter: 'all',
      regionCurrentFilter: 'all'
    }
  },

  methods: {

    onLangsPageChange(toPage, fromPage) {
      document.body.scrollTop = 0; // For Chrome, Safari and Opera
      document.documentElement.scrollTop = 0; // For IE and Firefox
    },

    /**
     * Get the label of the location (region)
     *
     */
    getRegionLabel: function (index) {
      return this.locations[+index + 1];
    },

    /**
     * Update the list of projects based on the filters
     *
     * @param $event
     * @param filterType : could be 'expertise', 'region' or 'categoryType'
     */
    updateSelectedFilter: function (e, filterType) {
      e.preventDefault();
      var filter = e.target.value;

      // Update the global filters
      switch (filterType) {
        case 'expertise':
          this.expertiseCurrentFilter = filter;
          break;
        case 'region':
          this.regionCurrentFilter = filter;
          break;
        case 'categoryType':
          this.categoryTypeCurrentFilter = filter;
          break;
      }

      if (this.regionCurrentFilter !== "all" || this.categoryTypeCurrentFilter !== "all" || this.expertiseCurrentFilter !== "all") {
        this.items = _.orderBy(this.getByAllFilters(_.filter(this.projectsArray, 'isAProject')), 'displayField', 'asc'); // filter projects
      } else {
        this.items = this.projectsArray // no filter
      }

      if (this.items.length) {
        document.querySelector("#pagi > li.left-arrow > a").click() // patch bug from plugin
      }
    },

    /**
     * Filter the project list by all the filters (Category + Region + Expertise)
     *
     * @param projects
     * @returns {*} updated list of projects with the 3 filters
     */
    getByAllFilters: function (projects) {
      return this.getByCategory(
          this.getByRegion(
              this.getByExpertise(
                  projects, this.expertiseCurrentFilter),
              this.regionCurrentFilter),
          this.categoryTypeCurrentFilter);
    },

    /**
     * Check which projects belong to the given category
     * One project belongs to one and only one category
     *
     * @param list
     * @param category
     * @returns {*} updated list of projects with the category filter
     */
    getByCategory: function (list, category) {
      if (!category || category === 'all') return list
      return list.filter(item => item.category === +category)
    },

    /**
     * Check which projects belong to the given region
     * One project belongs to one and only one region
     *
     * @param list
     * @param region
     * @returns {*} updated list of projects with the region filter
     */
    getByRegion: function (list, region) {
      if (!region || region === 'all') return list
      return list.filter(item => item.region === region)
    },

    /**
     * Check which projects belong to the given expertise
     * One project belongs to one or multiple expertise areas
     *
     * @param list
     * @param expertise
     * @returns {*} updated list of projects with the expertise filter
     */
    getByExpertise: function (list, expertise) {
      if (!expertise || expertise === 'all') return list
      return list.filter(item => item.expertise.includes(expertise))
    }

  },

  computed: {
    //
  },

}
</script>
