// import { createApp } from 'vue'
import Projects from "../vue/Projects.vue";
import Paginate from "vue-paginate";
import Vue from "vue";
import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import ScrollOut from "scroll-out";

// Intiate Vuejs instance if we find #appVue (just in Projects page)
const appVue = document.getElementById( "appVue" );
if (appVue) {
    Vue.use(Paginate)

    var app = new Vue({
        el: '#appVue',
        delimiters: ["${","}"],
        components: {
            Projects
        }
    });
}

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {


    // Navigation
    const navTriggers = document.querySelectorAll('.js-nav-btn');
    const mobNav = document.querySelector('.menu');
    const mobNavOverlay = document.querySelector('.menu-overlay');

    if(navTriggers){
        navTriggers.forEach(function(navTrigger) {
            bindEvent(navTrigger, "click", function() {
                event.preventDefault();
                document.body.classList.toggle('modal-open');
                navTrigger.classList.toggle('menu-icon--active');
                mobNav.classList.add('menu--transition');
                mobNav.classList.toggle('menu--active');
                navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }, false);
        });
    }


    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Home banner (Swiper)
    Swiper.use([Autoplay, Navigation, Pagination, EffectFade]);

    const banner = new Swiper('.banner .swiper-container', {
        loop: true,
        fadeEffect: {
            crossFade: true
        },
        speed: 500,
        effect: 'fade',
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
        slidersPerView: 1,
        navigation: {
            nextEl: '.banner__next',
            prevEl: '.banner__prev',
        }
    });


    // Projects block carousel (Swiper)
    const projects = new Swiper('.project .swiper-container', {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 0,
        navigation: {
            nextEl: '.project__next',
            prevEl: '.project__prev',
        }
    });


    // List block carousel (Swiper)
    // Used on small screens only

    // var list = Swiper;
    // var init = false;
    //
    // function listSwiper() {
    //     let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
    //
    //     if (mobile.matches) {
    //         if (!init) {
    //             init = true;
    //             list = new Swiper('.list .swiper-container', {
    //                slidesPerView: 'auto',
    //                spaceBetween: 0,
    //                navigation: {
    //                    nextEl: '.list__next',
    //                    prevEl: '.list__prev',
    //                }
    //            });
    //         }
    //     }
    //     else {
    //         if (init) {
    //             list.destroy();
    //             init = false;
    //         }
    //     }
    // }
    //
    // window.addEventListener('load', function() { listSwiper(); });
    // window.addEventListener('resize', function() { listSwiper(); });


    // Team modal
    let teamMembers = document.querySelectorAll('.team');
    let teamContents = document.querySelectorAll('.team-modal');
    let teamOverlay = document.querySelector('.modal-overlay');
    let teamCloseOverlay = document.querySelectorAll('.team-modal__close');

    function getTeamMemberDetails(index) {
        teamContents.forEach(item => {
            if(item.getAttribute('data-team') == index) { item.classList.add('active'); }
        })
        teamOverlay.classList.add('active');
    }

    if(teamMembers) {
        teamMembers.forEach(item => { item.addEventListener('click', function() { getTeamMemberDetails(this.getAttribute('data-team')); }) })
    }

    function removeOverlay() {
        teamContents.forEach(item => { item.classList.remove('active'); })
        teamOverlay.classList.remove('active');
    }

    if(teamOverlay) {
        teamOverlay.addEventListener('click', function() { removeOverlay(); })
    }

    if(teamCloseOverlay) {
        teamCloseOverlay.forEach(item => { item.addEventListener('click', function() { removeOverlay(); }) })
    }


    // Form fields and labels
    var formLabels = document.querySelectorAll('.form-input');

    if (formLabels) {
        formLabels.forEach(function(element) {
            element.addEventListener('blur', function(event) {
                if (!event.target.value) {
                    event.target.previousElementSibling.classList.remove('form-label--filled');
                }
            });
            element.addEventListener('focus', function(event) {
                event.target.previousElementSibling.classList.add('form-label--filled');
            });
        })
    }


    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener) {
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent) {
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

    ScrollOut({
        once: 'true'
    });

    const cursors = document.querySelectorAll('.cursor');
    const swiperPrev = document.querySelectorAll('.banner__prev');
    const swiperNext = document.querySelectorAll('.banner__next');

    if(cursors.length > 0) {
        cursors.forEach(function(cursor) {

            document.addEventListener('mousemove', e => {
                cursor.setAttribute("style", "top: "+(e.pageY - 60)+"px; left: "+(e.pageX - 60)+"px;")
            })

            for (var i = 0; i < swiperPrev.length; i++) {
                swiperPrev[i].addEventListener('mouseenter',  function(e) {
                    cursor.classList.add('left-cursor');
                });

                swiperPrev[i].addEventListener('mouseleave',  function() {
                    cursor.classList.remove('left-cursor');
                });
            }

            for (var i = 0; i < swiperNext.length; i++) {
                    swiperNext[i].addEventListener('mouseenter',  function(e) {
                        cursor.classList.add('right-cursor');
                    });

                    swiperNext[i].addEventListener('mouseleave',  function() {
                        cursor.classList.remove('right-cursor');
                    });
                }

        })
    }

})
